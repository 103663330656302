<template>
<section id="new-comment-container" class="new-comment-container">
  <div class="template-editor" v-if="editMode">
    <div class="row">
      <div :class="{'col-5':form.id, 'col-6':!form.id}">
        <b-form-input
          v-model="form.name"
          placeholder="Nome do comentário"
        />
        <div v-if="validated && !form.name" class="custom-invalid-feedback">
          Campo obrigatório
        </div>
      </div>
      <div class="col-5">
        <multiselect
          v-model="form.exam"
          track-by="id"
          label="value"
          class="with-border"
          :options="exams" 
          :showLabels="false" 
          placeholder="Selecionar exame"
          :searchable="true"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
        </multiselect>
        <div v-if="validated && !form.exam" class="custom-invalid-feedback">
          Campo obrigatório
        </div>
      </div>
      <div class="col-1">
        <b-button 
          v-if="form.id"
          variant="link" 
          @click="deleteModal"
        >
          <Trash class="trash" />
        </b-button>
        <b-button variant="link" @click="closeForm">
          <Close class="close" />
        </b-button>
      </div>
    </div>
    <v-text-area
      id="comentario"
      placeholder="Comentário"
      :value="form.template"
      @inputTextArea="value => { form.template = value }"
      rows="1"
    />
    <div v-if="validated && !form.template" class="custom-invalid-feedback">
      Campo obrigatório
    </div>
  </div>

  <b-button
    class="btn-new"
    variant="outline-primary"
    @click="handleButton"
  >
    <v-plus class="icon-plus" /> 
    {{ editMode ? 'Salvar alterações' : 'Novo comentário' }}
  </b-button>
</section>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Close from '@/assets/icons/close.svg'
import Plus from '@/assets/icons/plus.svg'
import Trash from '@/assets/icons/trash.svg'
import TextArea from '@/components/General/TextArea'

export default {

  components: {
    ChevronDown, Trash, Close,
    'v-plus': Plus,
    'v-text-area': TextArea,
  },

  props: {
    form: Object,
    exams: Array,
    edit: Boolean,
  },  

  data(){
    return {
      editMode: false,
      validated: false,
    }
  },

  methods: {
    isValid(){
      let isValid = true

      if( !this.form.exam ){
        isValid = false
        this.$toast.warning('Por favor, selecione um exame.')
      }
      if( !this.form.template ){
        isValid = false
        this.$toast.warning('Por favor, preencha o campo do comentário.')
      }
      if( !this.form.name ){
        isValid = false
        this.$toast.warning('Por favor, insira o nome do comentário.')
      }
      this.validated = true
      return isValid
    },
    handleButton(){
      if( this.editMode ){
        if( !this.isValid() ) return
        this.emitSave()
        this.validated = false
      } else {
        this.editMode = !this.editMode
      }
    },
    emitSave(){
      this.$emit('onSave', this.form)
    },
    deleteModal(template){
      this.$emit('delete', this.form)
    },
    closeForm(){
      this.editMode = false
      this.$emit('close')
    }
  },

  watch: {
    edit: function(value){
      if(value)
        this.editMode = this.edit
    }
  }

}
</script>

<style lang="scss">
#new-comment-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .template-editor {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--neutral-100);
    
    .row div:nth-child(3){
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      .trash {
        fill: none;
        margin-bottom: 10px;
        stroke: var(--states-red-soft);
      }

      .close {
        fill: var(--neutral-800);
        margin-bottom: 5px;
      }
    }
    
  }
  
  .btn-new {
    display: flex;
    font-weight: 700;
    color: var(--blue-500);
    flex-direction: row;
    gap: 10px;
    width: 180px;
    margin: 30px auto;
    align-items: center;
    white-space: nowrap;
    .icon-plus {
      width: 20px;
      height: 20px;
      fill: var(--blue-500);
    }
  }
}
</style>