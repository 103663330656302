<template>
  <b-table-simple striped class="table-eyecare">
    <thead>
      <tr>
        <th>Exame</th>
        <th>Comentário</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="template, index in templates" :key="index"
      >
        <td>{{ template.exame_oftalmologico.value}}</td>
        <td>{{template.name}}</td>
        <td class="text-right">
          <div class="more-with-menu">
            <MoreVertical class="more-icon" />
            <div class="menu">
              <b-button
                variant="link"
                @click="editItem(template)"
              >
                Editar
              </b-button>
              <b-button
                variant="link remove mb-0"
                @click="deleteModal(template)"
              >
                Remover
              </b-button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import MoreVertical from '@/assets/icons/more-vertical.svg'

export default {
  components: {
    MoreVertical,
  },

  props: {
    templates: Array,
  },

  methods: {
    editItem(template){
      this.$emit('edit', template)
    },
    deleteModal(template){
      this.$emit('delete', template)
    }

  },
}
</script>

<style lang="scss">
</style>