<template>
  <section id="exam-request-admin">
    <h1>Solicitação de exames</h1>
    <div class="header">
      <div class="col-6">
        <v-input-search
        id="search"
        v-model="filters.query"
        placeholder="Buscar comentário"
        debounce="500"
        />
      </div>
      <b-form-group class="col-6">
        <multiselect
          v-model="filters.exam"
          track-by="id"
          label="value"
          class="with-border"
          :options="exams" 
          :showLabels="false" 
          placeholder="Filtrar por exame"
          :searchable="true"
        >
          <template slot="caret" >
            <div class="chevron" v-if="!filters.exam">
              <ChevronDown />
            </div>
          </template>
        </multiselect>
        <span 
          v-if="filters.exam"
          id="help-icon" 
          class="icon-box close-icon"
          @click="() => { this.filters.exam = null}"
        >
          <Close class="icon stroke"  />
        </span>
      </b-form-group>
    </div>
    <div class="table">
      <v-exam-request-template-table
      :templates="templates"
      @edit="editItem"
      @delete="deleteModal"
    />
    <div class="pagination-position">
      <b-pagination
        v-model="filters.page"
        :total-rows="filters.count"
        :per-page="filters.limit"
        first-number
        last-number
        size="sm"
      />
    </div>
    <v-exam-request-template-form
      :form="form"
      :exams="exams"
      :edit="editMode"
      @onSave="save"
      @delete="deleteModal"
      @close="closeForm"
    />
  </div>
  </section>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import InputSearch from '@/components/General/InputSearch'
import ExamRequestTemplateForm from '@/components/Exam/ExamRequest/ExamRequestTemplateForm'
import ExamRequestTemplateTable from '../../components/Exam/ExamRequest/ExamRequestTemplateTable'
import Close from '@/assets/icons/close.svg'

export default {

  components: {
    ChevronDown, Close,
    'v-input-search': InputSearch,
    'v-exam-request-template-form': ExamRequestTemplateForm,
    'v-exam-request-template-table': ExamRequestTemplateTable,
  },

  data(){
    return {
      query: null,
      exams: [],
      templates: [],
      editMode: false,
      filters: {
        query: null,
        exam: null,
        paginate: 10,
        page: 1,
        count: 0,
        limit: 0,
      },
      form: {
        id: null,
        exam: null,
        name: null,
        template: '',
      }
    }
  },

  mounted(){
    this.getExamesOftalmologicos()
    this.getExamRequestTemplates()
  },

  methods: {
    closeForm(){
      this.editMode = false
      this.form = this.getDefaultForm()
    },
    editItem(template){
      this.form = {
        id: template.id,
        name: template.name,
        exam: template.exame_oftalmologico,
        template: template.template,
      }
      this.editMode = true
    },
    async deleteTemplate(template){
      const loading = this.$loading.show();
      try {
        await this.api.deleteExamRequestTemplates(template.id)
        this.closeForm()
        await this.getExamRequestTemplates()
        this.$toast.success('Comentário excluído com sucesso!')
      } catch ( err ){
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async deleteModal(template) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          Você tem certeza que deseja remover o comentário personalizado 
          <span>${template.name}</span>?
          <p>Não será possível reverter essa ação.</p>
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteTemplate(template)
        }
      })
    },
    async getExamRequestTemplates(){
      const loading = this.$loading.show();
      try {
        const response = await this.api.getExamRequestTemplates(this.filters)
        this.templates = response.data.data
        this.filters.count = response.data.total
        this.filters.limit = response.data.per_page
      } catch ( err ){
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async save(value){
      const loading = this.$loading.show();
      try {
        const data = {
          name: value.name,
          exames_oftalmologicos_option_id: value.exam.id,
          template: value.template
        }
        if(value.id){
          await this.api.updateExamRequestTemplates(value.id, data)
          this.$toast.success("Comentário atualizado com sucesso!")
        }
        else {
          await this.api.createExamRequestTemplates(data)
          this.$toast.success("Comentário adicionado com sucesso!")
        }
        await this.getExamRequestTemplates()
      } catch ( err ){
        this.$toast.error(err.message)
      } finally {
        this.form = this.getDefaultForm()
        loading.hide()
      }
    },
    async getExamesOftalmologicos(){
      const loading = this.$loading.show();
      try {
        const response = await this.api.getExamesOftalmologicos()
        this.exams = response.data
      } catch ( err ){
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },

    getDefaultForm(){
      return {
        id: null,
        exam: null,
        name: null,
        template: '',
      }
    }
  },
  watch: {
    page() { this.getExamRequestTemplates() },
    filters: { 
      handler() {
        this.getExamRequestTemplates() 
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss">
#exam-request-admin {
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 700;
    font-size: 1.5rem;
    padding: 40px 20px 0 20px;
  }

  .header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
    height: 87px;
    padding: 0 20px;
    border-bottom: 1px solid var(--neutral-200);
    background-color: var(--neutral-000);
    position: relative;
    
    .form-group {
      margin-bottom: 0 !important;

      .with-border {
        margin-bottom: 0 !important;
      }
    }

    .close-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 18px;

      svg {
        fill: var(--neutral-500);
      }
    }

    .icon {
      width: 25px;
      transition: all 500ms;
    }
    
  }
  
  .table {
    padding: 20px; 
  }

}
</style>